import React from "react";

import Logo from "../assets/Reviewai.svg";

const Footer = () => {
  return (
    <div id="Footer" className=" w-full border-t border-[#000000]/10">
      <div className="px-2 md:px-12 w-full max-w-screen-xl mx-auto flex flex-col md:flex-row justify-between items-center  text-center py-4">
        <div className="">
          <img src={Logo} alt="Logo" width={150} />
        </div>
        <div className="mt-3 md:mt-0 text-[10px] md:text-sm  text-[#000000]/60 font-light font-Raleway">
          © 2024 Copyright by Review.ai.
        </div>
      </div>
    </div>
  );
};

export default Footer;
