import React from "react";
import Navbar from "./components/Header/Navbar";
import HeroSection from "./components/HeroSection/HeroSection";
import OtherSection from "./components/OtherSection/OtherSection";
import Footer from "./components/Footer/Footer";

function App() {
  return (
    <div>
      <Navbar />
      <HeroSection />
      <OtherSection />
      <Footer />
    </div>
  );
}

export default App;
