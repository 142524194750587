import React, { useState } from "react";

import Improve from "../assets/improve.png";
import EnhanceUser from "../assets/enhanceUser.png";
import ValuableTime from "../assets/valuableTime.png";

import SimplyWorks from "../assets/SimplyWorks.svg";

import Tick from "../assets/tick.png";

import Middle from "../assets/middle.webp";

import { CircleMinus, CirclePlus } from "lucide-react";
const featureList = [
  {
    text: "AI-Powered Responses",
  },
  {
    text: "Customized Response",
  },
  {
    text: "Quick Response",
  },
  {
    text: "Increased User Engagement",
  },
  {
    text: "Issue Resolution Suggestions",
  },
  {
    text: "Improved Organic Reach",
  },
  {
    text: "Precised Response to problems",
  },
  {
    text: "Enhanced User Satisfaction",
  },
];

const faqList = [
  {
    question:
      "How does the AI System understand & generate relevant responses?",
    answer:
      "Yes, you can try us for free for 30 days. Our friendly team will work with you to get you up and running as soon as possible.",
  },
  {
    question: "Can I customize the responses to match my brand's voice?",
    answer:
      "Of course. Our pricing scales with your company. Chat to our friendly team to find a solution that works for you.",
  },
  {
    question: "Is my data secure with your service?",
    answer:
      "We understand that things change. You can cancel your plan at any time and we’ll refund you the difference already paid.",
  },
  {
    question: "Can other info be added to an invoice?",
    answer:
      "At the moment, the only way to add additional information to invoices is to add the information to the workspace's name.",
  },
  {
    question: "How does billing work?",
    answer:
      "Plans are per workspace, not per account. You can upgrade one workspace, and still have any number of free workspaces.",
  },
];
const OtherSection = () => {
  const [simpleWorks, setSimpleWorks] = useState("connect");
  const [openIndex, setOpenIndex] = useState(null);

  const toggleFAQ = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div>
      {/* Ratings*/}
      <div className="bg-[#F9FAFB] py-20 px-4 md:px-12">
        <div className="flex flex-col md:flex-row justify-center">
          <div className="w-full md:w-[33%] text-center">
            <div className="text-[#211f54] text-[68px] leading-[84px] font-bold">
              4.5 <span className="text-[#3a83ff]">*</span>
            </div>
            <div className="text-[#211f54] font-bold font-sans text-2xl mt-4">
              Improved Ratings
            </div>
            <div className="md:px-12 mt-4 text-base text-[#000000]/60 text-center">
              Lorem ipsum dolor sit amet consectet adipiscing elit eget
              quamumto.
            </div>
          </div>
          <div className="mt-8 md:mt-0 w-full md:w-[33%] text-center">
            <div className="text-[#211f54] text-[68px] leading-[84px] font-bold">
              1.67<span className="text-[#3a83ff]">M+</span>
            </div>
            <div className="text-[#211f54] font-bold font-sans text-2xl mt-4">
              Reviews Interaction
            </div>
            <div className="md:px-12 mt-4 text-base text-[#000000]/60 text-center">
              Lorem ipsum dolor sit amet consectet adipiscing elit eget
              quamumto.
            </div>
          </div>
          <div className="mt-8 md:mt-0 w-full md:w-[33%] text-center">
            <div className="text-[#211f54] text-[68px] leading-[84px] font-bold">
              100 <span className="text-[#3a83ff]">k+</span>
            </div>
            <div className="text-[#211f54] font-bold font-sans text-2xl mt-4">
              Downloads
            </div>
            <div className="md:px-12 mt-4 text-base text-[#000000]/60 text-center">
              Lorem ipsum dolor sit amet consectet adipiscing elit eget
              quamumto.
            </div>
          </div>
        </div>
      </div>
      {/* Benefits*/}
      <div className="py-24 px-4 md:px-32" id="benefits">
        <div className="font-sans text-5xl text-center text-black font-bold">
          Benefits
        </div>
        <div className="text-lg text-center text-[#000000]/80 mt-4">
          Connect your tools, connect your teams to enjoy the Benefits
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-10 mt-12">
          <div className="bg-[#F9FAFB] text-center py-8 px-12 rounded-lg">
            <div className="flex flex-row justify-center">
              <img src={Improve} alt="Improve React" width={80} />
            </div>

            <div className="text-2xl font-bold font-sans text-black mt-4">
              Improve your Organic Reach
            </div>
            <div className="text-[#000000]/60 text-base font-poppins mt-4">
              Elevate Your Online Presence and Expand Reach Organically. Boost
              Engagement and Grow Your Influence Effectively.
            </div>
          </div>
          <div className="bg-[#F9FAFB] text-center py-8 px-12 rounded-lg">
            <div className="flex flex-row justify-center">
              <img src={EnhanceUser} alt="Enhance User" width={80} />
            </div>

            <div className="text-2xl font-bold font-sans text-black mt-4">
              Enhance user Engagement
            </div>
            <div className="text-[#000000]/60 text-base font-poppins mt-4">
              Elevate User Engagement effortlessly. Foster meaningful
              connections and create a vibrant online community.
            </div>
          </div>
          <div className="bg-[#F9FAFB] text-center py-8 px-12 rounded-lg">
            <div className="flex flex-row justify-center">
              <img src={ValuableTime} alt="Valuable Time" width={80} />
            </div>

            <div className="text-2xl font-bold font-sans text-black mt-4">
              Save your Valuable Time
            </div>
            <div className="text-[#000000]/60 text-base font-poppins mt-4">
              Optimize your workflow and reclaim valuable moments for what
              matters most.
            </div>
          </div>
        </div>

        <div className="flex flex-row justify-center mt-16">
          <button className="text-center font-bold ml-4 px-12 py-3 bg-[#443fde] text-[#ffffff]  rounded-lg hover:bg-[#28257e] hover:scale-105">
            Get in Touch
          </button>
        </div>
      </div>
      {/* how it works*/}
      <div className="py-24 px-4  md:px-32" id="how-it-works">
        <div className="font-sans text-3xl md:text-5xl text-center text-black font-bold">
          How it Works ?
        </div>
        <div className="text-base md:text-lg text-center text-[#000000]/80 mt-4">
          Our SAAS uses cutting-edge AI technology to automatically respond to
          Play Store reviews.
        </div>

        <div className="flex flex-col md:flex-row mt-12">
          <div className="w-full md:w-[50%]">
            <div
              className={
                simpleWorks === "connect"
                  ? "border-l-[6px] border-[#3a83ff] px-8 py-8 cursor-pointer"
                  : "border-l-[6px] border-[#ffffff] px-8 py-8 cursor-pointer"
              }
              onClick={() => setSimpleWorks("connect")}
            >
              <div className="text-black font-sans font-semibold text-xl md:text-2xl">
                Simply connect your app
              </div>
              <div className="text-[#000000]/60 text-sm md:text-base font-poppins mt-4">
                Whether you have a team of 2 or 200, our shared team inboxes
                keep everyone on the same page and in the loop.
              </div>
            </div>
            <div
              className={
                simpleWorks === "customize"
                  ? "border-l-[6px] border-[#3a83ff] px-8 py-8 cursor-pointer mt-4"
                  : "border-l-[6px] border-[#ffffff] px-8 py-8 cursor-pointer mt-4"
              }
              onClick={() => setSimpleWorks("customize")}
            >
              <div className="text-black font-sans font-semibold text-xl md:text-2xl">
                Customize response templates
              </div>
              <div className="text-[#000000]/60 text-sm md:text-base font-poppins mt-4">
                An all-in-one customer service platform that helps you balance
                everything your customers need to be happy.
              </div>
            </div>
            <div
              className={
                simpleWorks === "watch-us"
                  ? "border-l-[6px] border-[#3a83ff] px-8 py-8 cursor-pointer mt-4"
                  : "border-l-[6px] border-[#ffffff] px-8 py-8 cursor-pointer mt-4"
              }
              onClick={() => setSimpleWorks("watch-us")}
            >
              <div className="text-black font-sans font-semibold text-xl md:text-2xl">
                Watch as our system engages with your users
              </div>
              <div className="text-[#000000]/60 text-sm md:text-base font-poppins mt-4">
                Measure what matters with Untitled’s easy-to-use reports. You
                can filter, export, and drilldown on the data in a couple
                clicks.
              </div>
            </div>
          </div>

          <div className="w-full md:w-[50%] flex flex-row justify-center">
            <img src={SimplyWorks} alt="Simply Works" width={300} />
          </div>
        </div>
        <div className="md:flex hidden">
          <button className="mt-8 text-center font-bold ml-8 px-12 py-3 bg-[#443fde] text-[#ffffff]  rounded-lg hover:bg-[#28257e] hover:scale-105">
            Talk to us
          </button>
        </div>
        <div className="md:hidden">
          <div className="flex flex-row justify-center">
            <button className="mt-16 text-center font-bold px-12 py-4 bg-[#443fde] text-[#ffffff]  rounded-lg hover:bg-[#28257e] hover:scale-105">
              Talk to us
            </button>
          </div>
        </div>
      </div>
      {/* List Of Features*/}
      <div className="md:py-24 px-4 md:px-32" id="features">
        <div className="font-sans text-3xl md:text-5xl text-center text-black font-bold">
          List of Features
        </div>
        <div className="text-base md:text-lg text-center text-[#000000]/80 mt-4">
          You can filter, export, and drilldown on the data in a couple clicks.
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 md:gap-10 mt-12">
          {featureList.map((ele, i) => {
            return (
              <div key={i} className="flex flex-row items-center">
                <img src={Tick} alt="Tick Mark" width={30} />
                <div className="text-base md:text-xl text-[#000000]/90 font-poppins ml-4">
                  {ele.text}
                </div>
              </div>
            );
          })}
        </div>

        <div className="flex flex-row justify-center mt-16">
          <button className="text-center font-bold ml-4 px-12 py-3 bg-[#443fde] text-[#ffffff]  rounded-lg hover:bg-[#28257e] hover:scale-105">
            Connect Now
          </button>
        </div>
      </div>

      {/* Faq*/}
      <div className="py-24 px-4 md:px-32" id="faq">
        <div className="font-sans text-3xl md:text-5xl text-center text-black font-bold">
          Frequently asked questions
        </div>
        <div className="text-base md:text-lg text-center text-[#000000]/80 mt-4">
          Everything you need to know about the product and billing.
        </div>
        <div className="mt-12">
          {faqList.map((faq, index) => (
            <div
              key={index}
              className={
                index === faqList.length - 1
                  ? "py-6"
                  : "py-6 border-b border-[#000000]/20"
              }
            >
              <div
                className="font-semibold text-base md:text-xl font-poppins cursor-pointer flex flex-row justify-between"
                onClick={() => toggleFAQ(index)}
              >
                {faq.question}{" "}
                <div>
                  {openIndex === index ? (
                    <CircleMinus className="text-[#000000]/50" />
                  ) : (
                    <CirclePlus className="text-[#000000]/50" />
                  )}{" "}
                </div>
              </div>
              {openIndex === index && (
                <div className="text-base md:text-lg text-[#000000]/70 mt-3">
                  {faq.answer}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      {/* business height*/}
      <div className="pb-16 px-4 md:px-32">
        <div className="bg-[#F9FAFB] rounded-lg py-12">
          <div className="flex flex-nowwrap justify-center pb-6">
            <img
              src={Middle}
              alt="Middle"
              width={80}
              className="rounded-[50%] relative"
            />
          </div>
          <div className="font-sans text-3xl md:text-4xl text-center text-black font-bold">
            Take Your Business to New Heights Now!
          </div>
          <div className="px-4 md:px-48 text-center text-base md:text-lg text-[#000000]/80 mt-4">
            Harness the power of positive reviews, build trust, and elevate your
            online presence effortlessly. Take charge of your reputation and
            attract more customers with our powerful review management platform.
          </div>
          <div className="flex flex-row justify-center mt-8">
            <button className="text-center font-bold ml-4 px-12 py-3 bg-[#443fde] text-[#ffffff]  rounded-lg hover:bg-[#28257e] hover:scale-105">
              Communicate Now
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OtherSection;
