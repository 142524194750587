import React from "react";

import Hero from "../assets/heroSection.png";
import Star from "../assets/star.svg";
import UserOne from "../assets/userOne.png";
import UserTwo from "../assets/userTwo.png";
const StarData = [
  { img: UserOne },
  { img: UserTwo },
  { img: UserOne },
  { img: UserTwo },
  { img: UserOne },
];
const HeroSection = () => {
  return (
    <div className="py-36 md:px-32">
      <div className="flex flex-col md:flex-row">
        <div className="w-full md:w-[50%] px-4 py-4">
          <div className="text-center md:text-start text-3xl md:text-5xl text-[#211f54] font-sans font-bold">
            Automate Play Store <div>Review Responses</div> <div>with AI</div>
          </div>
          <div className="text-center md:text-start text-base md:text-xl text-[#000000]/60 font-poppins mt-10">
            Powerful, self-serve product and growth analytics to help you
            convert, engage, and retain more.
          </div>
          <div className="flex flex-row mt-10">
            <div className="px-10 md:px-12 py-3 bg-white text-[#000000]/70 border border-[#000000]/30 rounded-lg hover:border-[#28257e] hover:text-[#28257e] cursor-pointer hover:scale-105">
              Learn More
            </div>
            <div className="font-bold ml-6 md:ml-4 px-10 md:px-12 py-3 bg-[#443fde] text-[#ffffff]  rounded-lg hover:bg-[#28257e] hover:scale-105 cursor-pointer">
              Let's Talk
            </div>
          </div>

          <div className="flex flex-row mt-10">
            <div>
              {StarData.map((ele, i) => {
                return (
                  <div
                    style={{
                      marginLeft: i === 0 ? "0px" : "-10px",
                    }}
                    className="inline-block w-[30px] h-[27px] md:w-[40px] md:h-[35px]"
                    key={i}
                  >
                    <img src={ele.img} alt="User" className="rounded-[50%]" />
                  </div>
                );
              })}
            </div>
            <div className="ml-6">
              <div className="flex flex-row items-center ">
                {" "}
                <img src={Star} alt="Star" />
                <div className="font-bold text-black text-base ml-3">5.0</div>
              </div>
              <div className="text-base text-[#000000]/80">
                from 200+ reviews
              </div>
            </div>
          </div>
        </div>
        <div className="px-4 w-full md:w-[50%] mt-8 md:mt-0">
          <img src={Hero} alt="Hero Section" />
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
