import React from "react";

const MobileNav = ({ navOpen, closeMobileNav, ...props }) => {
  return (
    <div
      className={`w-full h-full bg-white text-black mt-12 pt-8 fixed top-0 z-40 font-Poppins transform transition ease-in duration-500   ${
        navOpen ? "translate-y-0 animate-slideInNav" : "-translate-y-full "
      }`}
    >
      <a href="#how-it-works" onClick={closeMobileNav}>
        <div className="text-black hover:text-black">
          <div className="w-[85vw] px-2 py-4 border-b border-black/10 cursor-pointer mx-auto">
            How it Works
          </div>
        </div>
      </a>
      <a href="#features" onClick={closeMobileNav}>
        <div className="text-black hover:text-black">
          <div className="w-[85vw] px-2 py-4 border-b border-black/10 cursor-pointer mx-auto">
            Features
          </div>
        </div>
      </a>

      <a href="#benefits" onClick={closeMobileNav}>
        <div className="text-black hover:text-black">
          <div className="w-[85vw] px-2 py-4 border-b border-black/10 cursor-pointer mx-auto">
            Benefits
          </div>
        </div>
      </a>

      <a href="#faq" onClick={closeMobileNav}>
        <div className="text-black hover:text-black">
          <div className="w-[85vw] px-2 py-4 border-b border-black/10 cursor-pointer mx-auto">
            FAQ
          </div>
        </div>
      </a>
      {/*-------------------------Contact on Email-------------------- */}
    </div>
  );
};

export default MobileNav;
